<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card v-if="event" :elevation="isEmbedded ? 0 : 2">
      <v-alert type="warning" v-if="event.notPublished">This event is not published and not visible on live site.</v-alert>
      <EventHeader v-if="!isEmbeddedInApp" :event="event"/>
      <EventFriendReferral :event="event"/>
      <!-- <br/> -->
      <v-alert v-if="isConnected" tile prominent type="success">
        <p>{{$t('events.welcome.joined-message', {event: event.name})}}</p>
        <p>
          <span v-if="status.verification_code && event.verification_method === 'REG_DATA'">{{$t('events.welcome.joined-code', {code: status.verification_code})}}</span>
          <span v-if="status.races">{{$t('events.welcome.joined-races', {races: status.races.join(', ') })}}</span>
        </p>
        <p v-if="status.reg_name">
          <i18n path="events.welcome.reg-name">
            <strong >{{status.reg_name}}</strong>
          </i18n>
        </p>
        <p v-if="status.teams && status.teams.length">
          <i18n path="events.welcome.team-name">
            <strong >{{status.teams.map(x => x.name).join(', ')}}</strong>
          </i18n>
          </p>
        <!-- <p v-if="status.team_name">
          <i18n path="events.welcome.team-name">
            <strong >{{status.team_name}}</strong>
          </i18n>
        </p> -->
      </v-alert>

      <v-card-title class="headline">{{$t('events.welcome.how-it-works')}}</v-card-title>
      <v-card-text v-if="isConnected && event.instructions">
        <vue-markdown class="markdown" :html="false">{{ event.instructions }}</vue-markdown>
      </v-card-text>
      <div v-if="isConnected">
        <v-card-text>

            <div class="mb-4">
              <v-btn v-if="status && status.show_team_management" class="mr-4" :to="{name: 'profileGroups'}">Manage your team</v-btn>
              <v-btn v-if="status && status.allow_team_lookup && !event.join_block_team_create && !event.join_random_team_assignment" class="mr-4" @click="createCustomGroup">Create a new team</v-btn>
              <!-- <v-btn v-if="status && status.allow_custom_team && !status.allow_team_lookup && !event.join_random_team_assignment" class="mr-4" @click="joinCustomGroup">Join a team</v-btn> -->
              
              <v-dialog v-if="status && status.allow_team_lookup && !event.join_random_team_assignment" v-model="joinTeam" max-width="400px">
                <template v-slot:activator="{ on }">
                  <v-btn class="mr-4" v-on="on">Join a team</v-btn>
                </template>
                <v-card>
                  <v-card-title>{{$t('events.welcome.join-team-join-a-team')}}</v-card-title>
                  <v-card-text>
                    {{$t('events.welcome.join-join-team-validation-msg')}}
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-autocomplete
                      v-model="selectedGroupId"
                      :items="availableGroups"
                      :label="$t('events.welcome.join-team-select')"
                      item-text="name"
                      item-value="id"
                      />
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn color="secondary" text @click="joinTeam = false">{{$t('shared.cancel')}}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" :disabled="!selectedGroupId" @click="joinTeamByName();" :loading="$store.getters.isLoading">{{$t('shared.confirm')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>              
              
              
              <v-dialog v-if="status && status.allow_joining_another_race && tenant.id !='cofi'" v-model="joinAnotherDialog" max-width="400px">
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="green" class="mr-4" v-on="on"><v-icon small class="mr-2">fa fa-plus</v-icon> {{$t('events.welcome.join-race')}}</v-btn>
                </template>
                <v-card>
                  <v-card-title>{{$t('events.welcome.join-another-race')}}</v-card-title>
                  <v-card-text>
                    {{$t('events.welcome.select-race-to-join')}}
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-radio-group v-model="selectedRaceId">
                      <v-radio v-for="(item, idx) in event.races.filter(x=>!x.team)" :key="idx" :label="item.name" :value="item.id"/>
                    </v-radio-group>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn color="secondary" text @click="joinAnotherDialog = false">{{$t('shared.cancel')}}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" :disabled="!selectedRaceId" @click="joinAnotherRace();joinAnotherDialog = false" :loading="$store.getters.isLoading">{{$t('shared.confirm')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>              
              
              <v-dialog v-if="status && status.allow_custom_race_selection && tenant.id !='cofi'" v-model="selectRacesDialog" max-width="400px">
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="green" class="mr-4" v-on="on"><v-icon small class="mr-2">fa fa-plus</v-icon> {{$t('events.welcome.select-races')}}</v-btn>
                </template>
                <v-card>
                  <v-card-title>{{$t('events.welcome.select-races')}}</v-card-title>
                  <v-card-text>
                    {{$t('events.welcome.select-races-to-join')}}
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-checkbox v-for="(item, idx) in event.races.filter(x=>!x.team && !x.on_demand)" :key="idx" v-model="selectedRaceIds" :label="item.name" :value="item.id" hide-details></v-checkbox>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn color="secondary" text @click="selectRacesDialog = false">{{$t('shared.cancel')}}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="customizeRaces();selectRacesDialog = false" :loading="$store.getters.isLoading">{{$t('shared.confirm')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>              
              
              <v-dialog v-if="!isEmbeddedInApp" v-model="disconnectDialog" max-width="400px">
                <template v-slot:activator="{ on }">
                  <v-btn text color="red" v-on="on"><v-icon small class="mr-2">fa fa-unlink</v-icon> {{$t('events.welcome.disconnect')}}</v-btn>
                </template>
                <v-card>
                  <v-card-title>{{$t('events.welcome.disconnect-title')}}</v-card-title>
                  <v-card-text>
                    {{$t('events.welcome.disconnect-message')}}
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-switch v-model="deleteResults" class="mt-4" persistent-hint :hint="$t('events.welcome.disconnect-delete-results')" :label="$t('events.welcome.disconnect-delete-results-switch')"></v-switch>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn color="secondary" text @click="disconnectDialog = false">{{$t('shared.cancel')}}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="disconnectEvent();disconnectDialog = false">{{$t('shared.confirm')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>              
            </div>

            <div v-if="isConnected && tenant.id !== 'cofi'">
              <h3 class="subtitle">Activity Filtering</h3>
              <p>
                <span v-if="!eventSettings.activities_from && !eventSettings.activities_till">All your activities may count towards this event.</span>
                <span v-else-if="eventSettings.activities_from && eventSettings.activities_till">Only activities between {{ eventSettings.activities_from | localDate }} (included) and {{ eventSettings.activities_till | localDate }} (excluded) may count.</span>
                <span v-else-if="eventSettings.activities_from">Only activities on or after {{ eventSettings.activities_from | localDate }} may count.</span>
                <span v-else-if="eventSettings.activities_till">Only activities before {{ eventSettings.activities_till | localDate }} may count.</span>
                <v-btn color="primary" text @click="showEventSettingsDialog=true">Configure</v-btn>
              </p>
            </div>
            <div v-if="isConnected && status.teams && status.teams.length">
              <h3 class="subtitle">Your Team(s)</h3>
              <v-list class="mx-n4">
                <v-divider/>
                <template v-for="(item, idx) in status.teams">
                  <v-list-item :key="idx">
                    <v-list-item-content>
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn outlined :color="event.color || 'primary'" :to="{name: 'profileGroupsEvent', params: {id: item.id, eventId: event.id}}"> {{$t('shared.view')}}</v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider  :key="`sep-${idx}`"/>
                </template>
              </v-list>
            </div>

            <v-dialog v-if="isConnected" v-model="showEventSettingsDialog" max-width="400px">
              <v-card>
                <v-card-title>Configure Event Settings</v-card-title>
                <v-card-text>
                  You can restrict which activities may count toward this event based on the start date of an activity. 
                </v-card-text>
                <v-card-text>
                  This allows you to participate in multiple events while controlling which activities are allowed to count for each event.
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text>
                  <DateWithTimeZonePicker v-model="eventSettings.activities_from" :timeZone="event.timeZoneOlson" clearable :minDate="event.from" :maxDate="event.till" label="Only activities on or after (included)" class="pt-0 mt-4" />
                  <DateWithTimeZonePicker v-model="eventSettings.activities_till" :timeZone="event.timeZoneOlson" clearable :minDate="event.from" :maxDate="event.till" label="Only activities before (excluded)" class="pt-0 mt-0" />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn color="secondary" text @click="showEventSettingsDialog = false">{{$t('shared.cancel')}}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="updateEventSettings" :loading="$store.getters.isLoading">{{$t('shared.confirm')}}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>                 

            <div v-if="onDemandRacesAvailable && onDemandRacesAvailable.length > 0" class="mb-4">
              <h3 class="subtitle">{{$t('events.welcome.on-demand-title')}}</h3>
              <p>{{$t('events.welcome.on-demand-msg')}}</p>
              <v-list class="mx-n4">
                <v-divider/>
                <template v-for="(item, idx) in onDemandRacesAvailable">
                  <v-list-item :key="idx" two-line>
                    <v-list-item-content>
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                    <v-list-item-subtitle>{{item.result_desc}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn outlined :color="event.color || 'primary'" @click="selectedRaceId = item.id; startOnDemandDialog=true"> {{$t('events.welcome.start')}}</v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider  :key="`sep-${idx}`"/>
                </template>
              </v-list>

              <v-dialog v-if="selectedRace" v-model="startOnDemandDialog" max-width="600px">
                <v-card>
                  <v-card-title>{{selectedRace.name}}</v-card-title>
                  <v-card-text>
                    <vue-markdown v-if="selectedRace.result_desc" class="markdown" :html="false">{{selectedRace.result_desc }}</vue-markdown>
                  </v-card-text>
                  <v-divider class="mb-4"></v-divider>
                  <v-card-text>
                    <DateWithTimeZonePicker v-model="joinRaceRequest.start_date" :timeZone="event.timeZoneOlson" clearable :minDate="event.from" :maxDate="event.till" label="Start date" class="pt-0 mt-0" hint="Leave empty to start today." />
                    
                    <p v-if="selectedRace.personal_goal && selectedRace.scoring == 'BADGES' && selectedRace.badge_scoring == 'DAILY_TARGET'" class="mt-4 pb-0">
                      This leaderboard allows you to set personal goals. Leave them empty to use the default settings.
                    </p>

                    <DistanceTextArea
                      v-if="selectedRace.personal_goal && selectedRace.activity_types && selectedRace.activity_types.includes('DAILY_SUMMARY') && selectedRace.scoring == 'BADGES' && selectedRace.badge_scoring == 'DAILY_TARGET'"
                      v-model="joinRaceRequest.target_steps" 
                      :mode="$helpers.UnitType.NUMBER"
                      :label="`Daily Step Target (per day)`" 
                      hint="Enter your daily target in steps."
                      />
                    <DistanceTextArea
                      v-if="selectedRace.personal_goal && (!selectedRace.activity_types || !selectedRace.activity_types.includes('DAILY_SUMMARY')) && selectedRace.scoring == 'BADGES' && selectedRace.badge_scoring == 'DAILY_TARGET'"
                      v-model="joinRaceRequest.target_active_time_s" 
                      :mode="$helpers.UnitType.NUMBER"
                      :multiplier="60"
                      :label="`Daily Active Time Target (per day)`" 
                      hint="Enter your daily target in minutes."
                      />
                    <DistanceTextArea
                      v-if="selectedRace.personal_goal && selectedRace.activity_types && selectedRace.activity_types.includes('DAILY_SUMMARY') && selectedRace.scoring == 'BADGES' && selectedRace.badge_scoring == 'DAILY_TARGET'"
                      v-model="joinRaceRequest.increment_steps" 
                      :mode="$helpers.UnitType.NUMBER"
                      :label="`Increment steps (per day)`" 
                      hint="Enter weekly increase in steps.  The daily goal will increase each week with this number of steps. This will happen every 7 days from the day you have started."
                      />
                    <DistanceTextArea
                      v-if="selectedRace.personal_goal && (!selectedRace.activity_types || !selectedRace.activity_types.includes('DAILY_SUMMARY')) && selectedRace.scoring == 'BADGES' && selectedRace.badge_scoring == 'DAILY_TARGET'"
                      v-model="joinRaceRequest.increment_active_time_s" 
                      :mode="$helpers.UnitType.NUMBER"
                      :multiplier="60"
                      :label="`Increment active minutes (per day)`" 
                      hint="Enter weekly increase in minutes. This daily goal will increase each week with this number of minutes. This will happen every 7 days from the day you have started."
                      />
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-btn color="secondary" text @click="startOnDemandDialog = false">{{$t('shared.cancel')}}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="joinAnotherRace(selectedRace.id);startOnDemandDialog = false">{{$t('events.welcome.start')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>

            <div v-if="onDemandRacesJoined && onDemandRacesJoined.length" class="mb-4">
              <h3 class="subtitle">Your On-Demand Challenges</h3>
              <p>You have started the below on-demand challenges within this event.</p>
              <v-list class="mx-n4">
                <v-divider/>
                <template v-for="(item, idx) in onDemandRacesJoined">
                  <v-list-item :key="idx">
                    <v-list-item-title>{{item.name}}</v-list-item-title>
                    <v-list-item-action>
                      <v-btn text :color="event.color || 'primary'" @click="disconnectOnDemandRace(item)">Disconnect</v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider  :key="`sep-${idx}`"/>
                </template>
              </v-list>

            </div>

            <v-container v-if="!isEmbeddedInApp" class="pa-0">
              <v-row>
                <v-col cols="12" sm="4">
                  <h3 v-if="$oauth.hasConnection(profile)" class="subtitle">{{$t('profile.connections.manage')}}</h3>
                  <h3 v-else class="subtitle">{{$t('profile.fitness.add-device')}}</h3>
                  <p>
                    {{$t('events.welcome.connections-message')}}
                  </p>
                  <v-btn v-if="$oauth.hasConnection(profile)" outlined :to="{name: 'profileConnections'}" :color="event.color || 'primary'">{{$t('profile.manage-connections')}}</v-btn>
                  <v-btn v-else :to="{name: 'profileConnections'}" :color="event.color || 'primary'" :dark="$helpers.isDarkColor(event.color || 'primary')">{{$t('profile.fitness.add-device')}}</v-btn>
                </v-col>
                <v-col v-if="event.allow_manual_entry" cols="12" sm="4">
                  <div>
                    <h3 class="subtitle">{{$t('events.welcome.manual-entry-or-upload')}}</h3>
                    <p>{{$t('events.welcome.manual-entry-description')}}</p>
                    <v-btn outlined :color="event.color || 'primary'" :to="{name: 'activityEntry'}" class="mb-4" exact>{{$t('profile.activities.manualentry')}}</v-btn>
                  </div>
                  <!--<h3 v-else class="subtitle">{{$t('events.welcome.manual-upload')}}</h3>
                  <p>{{$t('events.welcome.manual-upload-description')}}</p>
                  <v-btn outlined color="primary" :to="{name: 'activityUpload'}" exact>{{$t('profile.activities.upload')}}</v-btn>-->
                </v-col>
                <v-col cols="12" sm="4"> 
                  <h3 class="subtitle">{{$t('events.welcome.customize-title')}}</h3>
                  <p>{{$t('events.welcome.customize-intro')}}</p>
                  <v-alert v-if="profile && profile.random_name" tile type="info">
                    <i18n path="profile.name-intro">
                      <strong>{{profile.name}}</strong>
                    </i18n>
                  </v-alert>
                  <v-btn outlined :to="{name:'profileEdit'}" :color="event.color || 'primary'" :dark="$helpers.isDarkColor(event.color || 'primary')" class="mr-4 mb-4">{{$t('events.welcome.personalize')}}</v-btn>
                  <v-btn text :color="event.color || 'primary'" :dark="$helpers.isDarkColor(event.color || 'primary')" :to="{name: 'profile'}">{{$t('events.welcome.view-profile')}}</v-btn>                
                </v-col>
              </v-row>
            </v-container>
            <div v-if="status.provider == 'sodisp'">
            </div>
            <!-- <v-btn class="mt-4" color="primary" outlined @click="testProfile">Test profile</v-btn> -->
        </v-card-text>
      </div>
      <v-card-text v-else>
        <EventJoinCard :event="event"/>
      </v-card-text>

      <div v-if="event.races && !isEmbeddedInApp">
        <v-card-title class="headline">{{$t('events.race.races')}}</v-card-title>
        <v-card-text>{{$t('events.welcome.race-sync-tip')}}</v-card-text>
        <RaceGrid :items="event.races.filter(x => x.visibility !== 'PRIVATE' && x.visibility !== 'MUTED')" :event="event" class="px-4 mb-8"></RaceGrid>
      </div>
    
      <v-divider></v-divider>

      <div class="grey lighten-4 ">
        <v-card-text>
          <vue-markdown v-if="event.rules_desc" class="markdown" :html="false">{{event.rules_desc }}</vue-markdown>
          <div v-else>
            <h3 class="headline">{{$t('events.rules.title')}}</h3>
            <ul class="mb-4 mt-2">
              <li>{{$t('events.rules.join_before', { from: $options.filters.localDate(event.from, "L LT"), till: $options.filters.localDate(event.till, "L LT") })}}</li>
              <li>{{$t('events.rules.period', { from: $options.filters.localDate(event.from, "L LT"), till: $options.filters.localDate(event.till, "L LT") })}}</li>
              <li v-if="event.allowed_history_days || event.allowed_history_days === 0">{{$t('events.rules.backloading', { days: event.allowed_history_days })}}</li>
              <li v-if="event.allow_manual_entry">{{$t('events.rules.manual-entry-allowed')}}</li>
              <li v-else>{{$t('events.rules.gps-only')}}</li>
            </ul>

            <p>
              {{$t('events.local-date-times-msg')}}
            </p>
          </div>
        </v-card-text>
        
      </div>
      <p v-if="user" class="text-muted">Logged in</p>

    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import eventService from "@/services/eventService";
import groupService from "@/services/groupService";
import EventHeader from '@/components/EventHeader.vue';
import EventJoinCard from '@/components/EventJoinCard.vue';
import EventFriendReferral from '@/components/EventFriendReferral.vue';
import DistanceTextArea from '@/components/DistanceTextArea.vue';
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import RaceGrid from '@/components/RaceGrid.vue';
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import VueMarkdown from '@/components/VueMarkdown.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Event",
  components: {
    EventHeader,
    EventJoinCard,
    EventFriendReferral,
    DateWithTimeZonePicker,
    VueMarkdown,
    RaceGrid,
    DistanceTextArea,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      status: null,
      profile: null,
      selectedRaceId: null,
      selectedRaceIds: [],
      disconnectDialog: false,
      joinAnotherDialog: false,
      joinTeam: false,
      availableGroups: null,
      selectedGroupId: null,
      selectRacesDialog: false,
      deleteResults: false,
      showEventSettingsDialog: false,
      startOnDemandDialog: false,
      eventSettings: {},
      joinRaceRequest: {},
    };
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.loadEvent(to.params.id);
    next();
  },

  async mounted() {
    await this.loadEvent(this.$route.params.id);
    
    EventBus.$on('login-state-change', async user => {
      //console.log('LOGIN CHANGED', user, this);
      await this.getEventStatus();
    });
    await this.getEventStatus();
  },

  methods: {
      
    async getEventStatus() {
      if (this.user) {
        var verificationCode = this.$route.query.verificationCode;
        var joinGroupId = this.$route.query.joinGroupId;
        var response = await profileService.getEventStatus(this.event.id);
        this.status = response.data;
        this.profile = (await profileService.get(/*full:*/false)).data;
        this.selectedRaceIds = this.status.race_ids || [];
        await this.maybeGetTeamNames();
        this.eventSettings = {
          activities_from: this.status.activities_from,
          activities_till: this.status.activities_till,
        };
        console.log('eventSettings', this.eventSettings);

        if (this.status && this.status.verification_required){
          this.$router.replace({ name: "eventJoin", params: { id: this.event.id}, query: {verificationCode: verificationCode, joinGroupId: joinGroupId} });
          return;
        }
      }
    },
    
    async maybeGetTeamNames() {
      if (this.user && this.event && this.status && this.status.allow_team_lookup) {
        var response = await groupService.getGroupsInEvent(this.event.id);
        this.availableGroups = response.data.data;
      }
    },

    async disconnectEvent() {
      //if (window.confirm('Are you sure you want to disconnect from this event?')) {
        await profileService.disconnectEvent(this.event.id, this.deleteResults);
        this.$router.replace({ name: "event", params: { event: this.event.id} });
      //}
      //await this.getEventStatus();
    },
    
    async joinAnotherRace(id) {
      await profileService.joinRace(this.event.id, this.selectedRaceId || id, this.joinRaceRequest);
      await this.getEventStatus();
      //this.$router.replace({ name: "event", params: { event: this.event.id} });
    },

    isRelay(item) {
      return item.team === 'RELAY_TIME_SLOTS' || item.team === 'RELAY_DISTANCE_SLOTS';
    },

    async updateEventSettings() {
      const response = (await profileService.putEventStatus(this.event.id, this.eventSettings)).data;
      this.$helpers.toastResponse(this, response, 'Successfully updated settings.');
      if (response.status == 'OK') {
        this.getEventStatus();      
        this.showEventSettingsDialog = false;
      }
    },

    async createCustomGroup() {
      const groupName = window.prompt('Please enter the name of your group/team:');
      if (groupName) {
        const response = (await profileService.createGroupForEvent(this.event.id, { name: groupName })).data;
        this.$helpers.toastResponse(this, response, 'Successfully created your group.');
        if (response.status == 'OK') {
          this.$router.replace({ name: "profileGroupsEvent", params: { id: response.id, eventId: this.event.id} });
        }
      }
    },
  
    async joinTeamByName() {
      if (this.selectedGroupId) {
        const response = (await profileService.joinGroupForEvent(this.event.id, { group_id: this.selectedGroupId })).data;
        this.$helpers.toastResponse(this, response, 'Successfully joined the team.');
        if (response.status == 'OK') {
          await this.getEventStatus();
          this.joinTeam = false;
          //this.$router.replace({ name: "profileGroupsEvent", params: { id: response.id, eventId: this.event.id} });
        }
      }
    },

    async joinCustomGroup() {
      const code = window.prompt('Please enter the join code of the group/team you want to join. Don\'t have a code? Contact the team captain to get one!');
      if (code) {
        const response = (await profileService.joinGroupForEvent(this.event.id, { code: code })).data;
        this.$helpers.toastResponse(this, response, 'Successfully joined the team.');
        if (response.status == 'OK') {
          this.$router.replace({ name: "profileGroupsEvent", params: { id: response.id, eventId: this.event.id} });
        }
      }
    },
    async disconnectOnDemandRace(race) {
      if (confirm(`Are you sure you want to stop the on-demand leaderboard ${race.name}? This will also remove your result (if any) from this leaderboard.`)) {
        const response = (await profileService.disconnectRace(this.event.id, race.id)).data;
        this.$helpers.toastResponse(this, response, `Successfully stopped with leaderboard ${race.name}.`);
        if (response.status == 'OK') {
          await this.getEventStatus();
        }
      }
    },
    async customizeRaces() {
      var request = { race_ids: this.selectedRaceIds || []};
      await profileService.joinEvent(this.event.id, request);
      await this.getEventStatus();
    },

    async loadEvent(id) {
      var response = await eventService.get(id);
      this.event = response.data;
      var meta = {
        title: this.event.name + ' on ' + tenant.name,
        description: this.event.description,
        image: this.event.img,
      };
      EventBus.$emit('page-header-change', meta);
    },
    

  },
  computed: {
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    onDemandRacesAvailable() {
      return (!this.status || !this.event || !this.event.races || !this.status.available_on_demand_race_ids || this.status.available_on_demand_race_ids.length == 0)
           ? []
           : this.event.races.filter(x => this.status.available_on_demand_race_ids.some(y => y === x.id) && (!this.status.on_demand_race_ids || !this.status.on_demand_race_ids.some(y => y === x.id)));
    },
    onDemandRacesJoined() {
      return (!this.status || !this.event || !this.event.races || !this.status.on_demand_race_ids || this.status.on_demand_race_ids.length == 0)
           ? []
           : this.event.races.filter(x => this.status.on_demand_race_ids.some(y => y === x.id));
    },
    selectedRace() {
      return !this.selectedRaceId
           ? null
           : this.event.races.find(x => x.id == this.selectedRaceId);
    },
    isConnected() {
      return this.status && this.status.connected;
    },
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: 'Welcome', disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">

</style>

